import React from "react";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import profile from "assets/img/faces/ugli.png";

import family_blood from "assets/img/jim_and_his_mates/family_blood.jpg";
import haters_gonna_hate from "assets/img/jim_and_his_mates/haters_gonna_hate.jpg";

import historien_om from "assets/img/opinionen/historien_om.jpg";
import o2018 from "assets/img/opinionen/2018.jpg";
import for_demokrati from "assets/img/opinionen/for_demokrati.jpg";
import jag_vill_dansa from "assets/img/opinionen/jag_vill_dansa.jpg";
import vill_inte_se from "assets/img/opinionen/vill_inte_se.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand="Ugli Sounds"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg0.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Ugli Sounds</h3>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                Musikbolaget Ugli Sounds grundades av
                {" "}<a href="http://fredrikkivi.se/" target="_blank">Fredrik Kivi</a>{" "}
                2014 i och med lansereringen av
                Opinionens debut Historien om.
                <a href="https://www.facebook.com/opinionenband" target="_blank">Opinionen</a>{" "}
                gav ut ytterligare fyra singlar innan upplösningen 2018. Ugli Sounds har även arbetat med
                {" "}<a href="https://www.facebook.com/Jim-and-his-mates-655426001299963" target="_blank">Jim and his Mates</a>.{" "}
                Alla utgivningar finns på relevanta plattformar som Spotify, Apple Music, Deezer, Google Play och Tidal.
                <br/>
                Musikbolaget är ett dotterbolag till
                {" "}<a href="https://www.allabolag.se/5568270093/ugli-ab" target="_blank">Ugli AB</a>.
                vars huvudsakliga verksamhet är IT.
              </p>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Opinionen",
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={historien_om}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={jag_vill_dansa}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={vill_inte_se}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={for_demokrati}
                              className={navImageClasses}
                            />
                              <img
                                  alt="..."
                                  src={o2018}
                                  className={navImageClasses}
                              />
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                      {
                          tabButton: "Jim and his Mates",
                          tabContent: (
                              <GridContainer justify="center">
                                  <GridItem xs={12} sm={12} md={4}>
                                      <img
                                          alt="..."
                                          src={family_blood}
                                          className={navImageClasses}
                                      />
                                      <img
                                          alt="..."
                                          src={haters_gonna_hate}
                                          className={navImageClasses}
                                      />
                                  </GridItem>
                              </GridContainer>
                          ),
                      },
                    {
                      tabButton: "Fredrik Kivi",
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src="https://live.staticflickr.com/2632/3681447024_908af59968_b.jpg"
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
